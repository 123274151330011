import React from 'react';
import { graphql } from 'gatsby';
import PT from 'prop-types';
import AuthContext from '../../contexts/AuthContext';
import TemplateWrapper from '../../layouts';
import ResourceSearch from '../../components/ResourceSearch';

class Members extends React.Component {
  render() {
    const { data } = this.props;
    const { resourcesMd } = data;
    function generateUpdatesElement(codeInputted) {
      return codeInputted ? (
        <ResourceSearch
          resources={resourcesMd.edges}
          resourceTypes={[
            'current repertoire',
            'choir+',
            'beginners',
            'christmas songs',
            'old repertoire',
          ]}
          teachTrackIntro
        />
      ) : (
        <p>Please log in :)</p>
      );
    }
    return (
      <TemplateWrapper>
        <AuthContext.Consumer>
          {({ codeInputted }) => {
            const updatesElement = generateUpdatesElement(codeInputted);
            return updatesElement;
          }}
        </AuthContext.Consumer>
      </TemplateWrapper>
    );
  }
}

Members.propTypes = {
  data: PT.shape({
    allMarkdownRemark: PT.object,
  }).isRequired,
};

export default Members;

export const pageQuery = graphql`
  query MembersQuery {
    site {
      siteMetadata {
        title
      }
    }
    resourcesMd: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "resources" } } }
    ) {
      edges {
        node {
          frontmatter {
            templateKey
            title
            type
            group
            parts
            sourceLink
            media
            extra
          }
        }
      }
    }
  }
`;
